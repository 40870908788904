* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root{
    --dark-blue: #03417d;
    --medium-blue: #104a97;
    --light-blue: #5C82BE;
    --font-color: #010101;
    --accent-color: #DC1B20;
    --shadow-color: rgba(92, 130, 190, 0.5);
}


/******** HEADER *********/
.logo {
    width: 125px;
}

header {
    height: 200px;
    background-image: url("../images/default.jpg");
    background-size: cover;
    background-position: center;
}



/****** Content Layout *****/

.action-list{
    margin: 25px;
}

.action-item{
    margin-bottom: 48px;
    padding: 24px;
}

/* Mise en forme spécifique à la section procurations actions */
.procuration-actions {
    display: flex;
    flex-direction: column;
}

.procuration-actions .action-item{
    /*border: 1px solid var(--light-blue);
    border-radius: 6px;
    box-shadow: 2px 2px 5px var(--shadow-color);*/
    flex: 1;
}
.action-item h3 {
    color: var(--dark-blue);
    padding: 0;
}

.procuration-actions p{
    margin-bottom: 36px;
}

.procuration-actions .link{
    border: 1px solid var(--accent-color);
    border-radius: 4px;
    color: var(--accent-color);
    font-weight: bold;
    margin: 16px 0;
    padding: 12px 18px;
    display: inline-block;
    margin-bottom: 0;
}

.procuration-actions .link:hover{
    background-color: var(--accent-color);
    color: white;
}



/* Mise en page lien de la dernière section action-item*/
.action-item a{
   color: var(--medium-blue);
   text-decoration: none;
}

.action-item a:hover {
    color: var(--light-blue);
 }




/*********** Footer ***************/

footer {
    background-color: var(--dark-blue);
    color: white;
    font-size: 10px;
    padding: 12px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
}

footer ul{
    margin-bottom: 0;
    list-style: none;
}

footer li {
    margin: 0;
    padding: 0;
}


/************* RESPONSIVE DESIGN **************/

/****** Tablet design ******/

@media only screen and (min-width: 768px) {
    h1{
        background-size: cover;
        background-position: center;
    }
}

@media only screen and (min-width:1224px){
    .logo{
        width: 150px;
    }
    .navbar-light .navbar-nav .nav-link{
        font-size: 20px;
    }

    h1{
        font-size: 3rem;
        margin-top: 71px;
        padding: 100px 0 200px 0;
        text-align: center;
    }

    .action-list{
        max-width: 900px;
        margin: 42px auto;
    }

    .procuration-actions{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 2%;
    }
    p {
        font-size: 18px;
    }
    .link {
        font-size: 18px;
    }

    .action-item > h4 {
        font-size: 28px;
    }
    .action-item > a {
        font-size: 18px;
    }
    footer {
        padding: 16px;
    }
    footer li {
        font-size: 14px;
        padding: 4px;
    }
}
