$input-focus-border-color: #104a97;

$theme-colors: (
    "primary": #104a97,
    "secondary": #03417D,
    "accent":  #dc1b20,
    "danger": teal
);
/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
